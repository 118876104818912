import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { format } from 'date-fns';

import { Hero } from 'components/hero/Hero';
import { Item } from 'components/item/Item';
import { BlockList } from 'components/block-list/BlockList';
import { Button } from 'components/button/Button';
import { Row } from 'components/row/Row';
import { Col } from 'components/col/Col';

export const query = graphql`
  query HeftiQuery($id: String) {
    contentfulHefti (id: { eq: $id }) {
      title
      volume
      issue
      published
      editors {
        name
      }
      chapters {
        id
        title
        articles {
          id
          title
          slug
          pageNumber
          author {
            name
            id
          }
          articlePdf {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default ({ data }: any) => {
  const { contentfulHefti: { volume, issue, published, chapters, editors, title } } = data;

  const heading = `${volume}. árgangur, ${issue}. hefti (${format(published, 'YYYY')})`;

  return (
    <>
      <Helmet title={heading} />

      <Hero
        title={`${title}`}
        subtitle={heading}
        contentHeading={editors && editors.length > 1 ? 'Ritstjórar:' : 'Ritstjóri:'}
        lines={editors && editors.map(({ name, id }: any) => ({ name, url: `/hofundar/${id}` }))}
      />
      {chapters && chapters.map(({ id, title, articles }: any) => (
        <BlockList
          heading={title}
          key={id}
        >
          {articles && articles.map(({ title: articleTitle, articlePdf, author, slug, id: articleId, pageNumber }: any) => {
            return (
              <Row
                key={articleId}
              >
                <Col col={10}>
                  <Item
                    to={`/grein/${slug}`}
                    title={articleTitle}
                    subtitle={author && author.map(({ name, id: authorId }: any) => (<span key={authorId}>{name}</span>))}
                  />
                </Col>
                <Col col={2}>
                  <h3>{pageNumber}</h3>
                  {articlePdf && (
                    <a href={`https://${articlePdf.file.url}`} target="_blank">
                      Sækja PDF
                    </a>
                  )}
                </Col>
              </Row>
            );
          })}
        </BlockList>
      ))}
    </>
  );
};
