import * as React from 'react';

import s from './Col.scss';

interface IColProps {
  children: React.ReactNode;
  col?: number;
}

export const Col = ({ children, col }: IColProps) => (
  <div className={s(s.col, `col${col}`)}>
    {children}
  </div>
);
