import * as React from 'react';

import { Container } from 'components/container/Container';
import { Col } from 'components/col/Col';
import { Row } from 'components/row/Row';
import { Item } from 'components/item/Item';

import s from './Hero.scss';

interface ILine {
  name: string;
  url: string;
}

interface IHeroProps {
  title: string;
  subtitle?: string;
  contentHeading: string;
  lines: ILine[];
  children?: React.ReactNode;
}

export const Hero = ({ title, subtitle, lines, contentHeading, children }: IHeroProps) => (
  <Container>
    <div className={s.hero}>
      <Row>
        <Col col={8}>
          <h1 className={s.hero__title}>{title}</h1>
          {subtitle && (
            <h2 className={s.hero__subtitle}>{subtitle}</h2>
          )}
          <p className={s.hero__contentHeading}>{contentHeading}</p>
          {lines && (
            <ul className={s.hero__list}>
              {lines.map(({ name, url }) => (
                <Item key={url} to={url} title={name} />
              ))}
            </ul>
          )}

        </Col>
        {children && (
          <Col>
            {children}
          </Col>
        )}
      </Row>
    </div>
  </Container>
);
