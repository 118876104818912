import * as React from 'react';

import { Container } from 'components/container/Container';
import { Row } from 'components/row/Row';
import { Col } from 'components/col/Col';

import s from './BlockList.scss';

interface IBlockListProps {
  heading: string;
  children: React.ReactNode;
}

export const BlockList = ({ heading, children }: IBlockListProps) => (
  <Container>

    <div className={s.block}>
      <Row>
        <Col col={8}>
          <div className={s.block__headingWrapper}>
            <h2 className={s.block__heading}>{heading}</h2>
          </div>
          <div className={s.block__content}>{children}</div>
        </Col>
      </Row>
    </div>
  </Container>
);
