import * as React from 'react';

import { Link } from 'components/link/Link';

import s from './Item.scss';

interface IItemProps {
  children?: React.ReactNode;
  title: string;
  subtitle?: string;
  to: string;
}

export const Item = ({ children, title, subtitle, to }: IItemProps) => (
  <Link to={to} className={s.item}>
    <h3 className={s.item__title}>
      {title}
    </h3>
    {subtitle && (
      <h3 className={s.item__subtitle}>
        {subtitle}
      </h3>
    )}
    {children}
  </Link>

);
